<template >
    <header >
      <button  @click="dark=!dark" class="btnhed" ></button>
      <div class="burger_menu_button">
          
                <Slide>
            <li>
              <router-link  to="/" ><img src="@/assets/burgic/ic1.svg" alt="" srcset="">Главная</router-link>
            </li>
                <li>
                  <router-link  to="/Intercom" ><img src="@/assets/burgic/ic3.svg" alt="" srcset="">Умный домофон</router-link>
                </li>
                <li>
                  <router-link  to="/Video" ><img src="@/assets/burgic/ic2.svg" alt="" srcset="">Видеонаблюдение</router-link>
                </li>
                <li>
                  <router-link  to="/Gates" ><img src="@/assets/burgic/ic4.svg" alt="" srcset="">Ворота и шлагбаумы</router-link>
                </li>
                <li>
                  <router-link  to="/ACMS" ><img src="@/assets/burgic/ic5.svg" alt="" srcset="">СКУД</router-link>
                </li>
                <li>
                  <router-link  to="/About" ><img src="@/assets/burgic/ic6.svg" alt="" srcset="">О нас</router-link>
                </li>
                <li>
                  <router-link  to="/Docs" ><img src="@/assets/burgic/ic7.svg" alt="" srcset="">Документы</router-link>
                </li>
  
              </Slide>
          </div>
  
          <a href="/" class="logo_block" >
          
          </a>
          <nav >
  
                
                <ul itemscope itemtype="http://www.schema.org/SiteNavigationElement">
                  <!-- <li><a href=""><router-link  to="/" active-class="active" class="activee">Главная</router-link></a></li> -->
                  <li itemprop="name">
                  <a ><router-link  to="/Intercom" active-class="active" class="activee">Умный домофон</router-link></a>
                  </li>
                  <li itemprop="name">
                  <a ><router-link  to="/Video" active-class="active" class="activee">Видеонаблюдение</router-link></a>
                  </li>
                  <li itemprop="name">
                  <a ><router-link  to="/Gates" active-class="active" class="activee">Ворота и шлагбаумы</router-link></a>
                  </li>
                  <li itemprop="name">
                  <a ><router-link  to="/ACMS" active-class="active" class="activee">СКУД</router-link></a>
                  </li>
                  <li class="dropdown" >
                    <a class="dropbtn">О нас</a>
                      <li class="dropdown-content" itemprop="name">
                        <RouterLink to="/About" active-class="active" class="activee">О нас</RouterLink>
                        <router-link to="/Docs" active-class="active" class="activee">Документы</router-link>
                    </li>
                  </li>
                
  
                <button class="theme" @click="dark=!dark"  ></button>
  
              
                <button class="NavFo" id="NavFo"
                @click="sShowFoModal=true,modal=!modal, modal=true"
                >
                  <p>Подать заявку</p>
                </button>
  
                <ShowFoModal
                v-if="sShowFoModal"
                @close="sShowFoModal = false, nextM=false, modal=false"
                @nextMclose = "nextM = false"
                @nextMopen = "nextM = true"
  
                />
               
                
                <nextModal
                v-if="nextM"
                @nextMclose = "nextM = false"
                @nextMopen = "nextM = true"
                @close="sShowFoModal = false, nextM=false,modal=false" 
  
                />
                
                
                
  
  
                </ul>
  
          </nav>
          
      </header>
  
  <router-view/>
  
  
    </template>
    
    <script>
  import ShowFoModal from '@/components/modal/ShowFoModal.vue';
  
  import { Slide } from 'vue3-burger-menu';
  
  import nextModal from '@/components/modal/nextModal.vue';
  
  export default {
  
      components: {
          Slide,
          ShowFoModal,
          nextModal,
      },
  
      data() {
      return {
        dark: false,
        sShowFoModal: false,
        nextM: false,
        modal:false,
        };
    },
    mounted: function() {
      this.root = document.documentElement;
  
    },
  
    watch: {
      dark: {
        handler: function() {
          this.$nextTick(() => {
            if (this.dark) {
              localStorage.color= 'Dark';
              this.root.style.setProperty("--main-background-color", "#150621");
              this.root.style.setProperty("--text-color-light", "white");
              this.root.style.setProperty("--main-second-color-bg","#1E2235");
              this.root.style.setProperty("--footer-color", "#1E2235");
              this.root.style.setProperty("--logo-light", 'url("./asset/logoW.svg")');
              this.root.style.setProperty("--strlef-light", 'url("./asset/strlfW.svg")');
              this.root.style.setProperty("--strrig-light", 'url("./asset/strrfW.svg")');
              this.root.style.setProperty("--but-light",' url("./asset/light.svg")');
              this.root.style.setProperty("--drop-down-color","#1E2235") ;
              this.root.style.setProperty("--close-modal",'url("./asset/close_dark.svg")');
            } else {
              localStorage.color= 'Light';
              this.root.style.setProperty("--main-background-color", "white");
              this.root.style.setProperty("--text-color-light", "black");
              this.root.style.setProperty("--main-second-color-bg","#23E3C6");
              this.root.style.setProperty("--footer-color", "#0B8E7A");
              this.root.style.setProperty("--logo-light", 'url("./asset/logo.svg")');
              this.root.style.setProperty("--strlef-light", 'url("./asset/strlf.svg")');
              this.root.style.setProperty("--strrig-light", 'url("./asset/strrf.svg")');
              this.root.style.setProperty("--but-light",' url("./asset/darkmod.svg")');
              this.root.style.setProperty("--drop-down-color","white")
              
             this.root.style.setProperty("--close-modal",'url("./asset/close.svg")');
            }
          })
        },
        immediate: true
      },
      modal: {
    handler: function() {
          this.$nextTick(() => {
            if (this.modal) {
              localStorage.modal= 'true';
              document.body.classList.add('modal_open');
  
  
            } else {
              localStorage.modal= 'false';
              document.body.classList.remove('modal_open');
            }
         
    })
    
  },
  immediate: true
      }
    },
    created(){
  
  
  
      if (localStorage.color) {
      if (localStorage.color == 'Light') {
        this.dark=false
  
      } else {
        this.dark=true
  
      }
    }else {
        this.dark=  window.matchMedia('(prefers-color-scheme: dark)').matches;
        
      }
    },
  }
  </script>
  <style>
  
    .bm-menu {
      top: 0;
      background-color: var(--main-background-color);
  
    }
    .bm-item-list {
      color: #b8b7ad;
      margin-left: 5%;
      font-size: 20px;
      margin-top: -30px;
    }
  </style>
    